import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

const initialState = {
  currentUser: null,
  currentItem: null,
  columns:[],
  queryData:[],
  itemID: null,
  itemTypeNewForm: "",
  currentForm: [],
  currentTab:"admin_tab",
  isFormLoading:true,
  isUserLoading:true,
  isPageLoading:true,
  itemTabs:[],
  currentQueryString: null,
  error: null,
  isLoading: false,
  servicePortalInfos:[],
  redirectForm: ""
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return {
        ...state,
        currentUser: action.payload,
        isUserLoading: false,
        error: null
    };
    case 'SET_CURRENT_ITEM':
      return {
        ...state,
        currentItem: action.currentItem
    };
    case 'SET_REDIRECT_FORM':
      return {
        ...state,
        redirectForm: action.redirectForm
    };
    case 'SET_SERVICE_PORTAL_INFOS':
      return {
        ...state,
        servicePortalInfos: action.servicePortalInfos,
        isUserLoading: false,
    };
    case 'SET_IS_USER_LOADING':
      return {
        ...state,
        isUserLoading: action.isUserLoading
    };
    case 'SHOW_NEW_FORM':
      return {
        ...state,
        itemTypeNewForm: action.itemTypeNewForm,
        isPageLoading: true
    };
    case 'SET_COLUMNS':
        return {
          ...state,
          columns: action.columns
    };
    case 'SET_CURRENT_FORM':
        return {
          ...state,
          currentForm: action.currentForm,
          itemID: action.itemID, 
          currentItem: action.currentItem,
          isFormLoading: false,
          isPageLoading: false
    };
    case 'SET_FORM_LOADING':
      return {
        ...state,
        isFormLoading: action.isFormLoading
    };
    case 'SET_PAGE_LOADING':
      return {
        ...state,
        isPageLoading: action.isPageLoading
    };
    case 'SET_ITEM_ID':
        return {
          ...state,
          itemID: action.itemID,
    };
    case 'SET_ITEM_TABS':
      return {
        ...state,
        itemTabs: action.itemTabs,
        currentTab: "admin_tab"
    };
    case 'SET_CURRENT_TAB':
      return {
        ...state,
        currentTab: action.currentTab,
    };
    case 'SET_QUERYDATA':
      return {
        ...state,
        queryData: action.queryData,
        isPageLoading: false
    };
    case 'LOGOUT':
      return {
        ...state,
        currentUser: null,
        error: null
      };
    default:
      return state;
  }
};

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk]
});

export {store};