// import external modules
import React, { Suspense, lazy } from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
// import internal(own) modules
import registerServiceWorker from "./registerServiceWorker";
import Spinner from "./components/spinner/spinner";
import { BrowserRouter} from "react-router-dom";
const LazyApp = lazy(() => import("./app/app"));

const container = document.getElementById('root');
const root = createRoot(container);
root.render(  
  <Provider store={store}>
    <BrowserRouter>
        <LazyApp />
    </BrowserRouter>
  </Provider>
      
);
registerServiceWorker();